import clsx from 'clsx';
import variablesJSS from 'static/styles/jss/abstracts/variables';

import { UiGenerateMargin, UIGetMarginLeftRight } from 'utils/handlers';

import useStyles from './styles';

const getInlineStyles = ({
  width,
  label,
  margin,
  fullWidth,
  direction,
  customStylesDiv,
}) => ({
  ...UiGenerateMargin(margin, direction),
  width: fullWidth ? `calc(100% - ${UIGetMarginLeftRight(margin)}px)` : width,
  ...(label && {
    display: 'flex',
    flexDirection: 'column',
  }),
  ...(!label && {
    display: 'block',
  }),
  ...customStylesDiv,
});

const InputWrapper = ({
  size,
  icon,
  type,
  label,
  width,
  theme,
  margin,
  errors,
  disabled,
  children,
  fullWidth,
  direction,
  className,
  iconClassName,
  customStylesDiv,
  transparentInput,
  customStylesInput,
}) => {
  const styles = useStyles({
    type,
    disabled,
    backgroundColor: [customStylesInput.background, '!important'],
    border: customStylesInput.border,
    labelColor: (label && label.color) || variablesJSS.$input.$baseColor,
    textAlign: customStylesInput.textAlign,
    marginBottom: '0px',
    iconTop: {},
    iconLeft: icon?.iconLeft && icon?.className,
  });

  return (
    <div
      style={getInlineStyles({
        fullWidth,
        margin,
        direction,
        width,
        label,
        customStylesDiv,
      })}
      className={clsx(
        className,
        styles[theme],
        styles['input-wrap'],
        styles[`theme-${theme}`],
        `${size}-parent-wrapper`,
        {
          'error-field': errors,
          'input-wrap--disabled': disabled,
          [styles['with-icon']]: iconClassName,
          [styles['transparent-input-wrap']]: transparentInput,
          [styles['wrap-with-checkbox']]: type === 'checkbox',
          [styles['wrap-with-radio']]: type === 'radio',
        },
      )}
    >
      {children}
    </div>
  );
};

export default InputWrapper;
