import { MediaQuery, Shadow, SubTitleInsideMainContent } from './mixins';
import variables from './variables';

const FlexInputsWrapper = {
  alignItems: 'baseline',
  display: 'flex',
  ...MediaQuery.down({
    '&.mobile-full-inputs': {
      flexDirection: 'column',
      '& > div': {
        width: '100% !important',
        marginRight: '0 !important',
        marginLeft: '0 !important',
      },
    },
  }).sm,
};

const CheckedIconBefore = {
  '&::before': {
    content: '""',
    top: '3px',
    left: '4px',
    width: '6px',
    height: '12px',
    border: 'solid',
    borderWidth: '0 2px 2px 0',
    '-webkit-transform': 'rotate(45deg)',
    '-ms-transform': 'rotate(45deg)',
    transform: 'rotate(45deg)',
    position: 'absolute',
    zIndex: '2',
  },
};

const MainContent = {
  padding: '0px',
  marginBottom: '16px',
  flex: '1 0 auto',
  '&.section-content--center': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .go-to-fill-profile': {
    fontSize: '13px',
    display: 'block',
    margin: '20px 0',
    color: '#fff !important',
    cursor: 'pointer',
    textDecoration: 'underline !important',
  },
  ...MediaQuery.up({
    '&:not(.no-background)': {
      padding: '20px',
      backgroundColor: variables.$baseColorLight_30,
      ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    },
  }).sm,
  ...MediaQuery.down({
    '&.save-bg-on--mobile': {
      padding: '20px',
      backgroundColor: variables.$baseColorLight_30,
      ...Shadow('0 3px 6px 0 rgba(0,0,0,0.15)'),
    },
  }).sm,
};

const AddListIconAddExtend = {
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  '& .input-field-wrapper': {
    width: 'calc(100% - 60px)',
    position: 'relative',
    '& > p': {
      position: 'absolute',
      top: 0,
      right: 0,
      ...SubTitleInsideMainContent({
        fontWeight: '600',
      }),
    },
  },
  '& .icon-Add': {
    width: '42px',
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: variables.$primaryColor,
    borderRadius: '50%',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '26px',
    '&[aria-disabled="true"]': {
      backgroundColor: '#375e90',
      pointerEvents: 'none',
    },
  },
};

const IconActiveState = {
  position: 'relative',
  '&::after': {
    position: 'absolute',
    content: '""',
    width: 'calc(100% + 15px)',
    height: 'calc(100% + 15px)',
    background: variables.$baseColorDark_60,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    borderRadius: '50%',
    zIndex: '-1',
  },
  '&:hover::after': {
    opacity: '0.3',
  },
};

const StickerBadgeSilver = {
  backgroundColor: '#0A2955',
  color: '#FCF200',
  margin: '0px 3px',
  fontSize: '12px',
  padding: '0px 7px',
  height: '20px',
  lineHeight: '20px',
  textTransform: 'uppercase',
  borderRadius: '4px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const StickerBadgeGold = {
  backgroundColor: '#CD3A3A',
  color: '#FCF200',
  margin: '0px 3px',
  fontSize: '12px',
  padding: '0px 7px',
  height: '20px',
  lineHeight: '20px',
  borderRadius: '4px',
  textTransform: 'uppercase',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

const LabelTag = {
  height: '18px',
  fontSize: '11px',
  color: '#98A4B3',
  padding: '3px 5px',
  background: '#094287',
  borderRadius: '2px',
};

const PopUpActionsBtn = {
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  fontSize: '13px',
  background: 'none',
  fontWeight: 'bold',
  textTransform: 'uppercase',
  color: variables.$primaryColor,
  display: 'flex',
  alignItems: 'center',
  lineHeight: '13px',
};

const OverflowThumb = {
  overflowX: 'hidden',
  scrollbarColor: props =>
    props.isDark ? '#C7C7C7 #07224D' : '#C7C7C7 #EFEFEF',
  scrollbarWidth: 'thin',
  '&::-webkit-scrollbar': {
    width: '6px',
    backgroundColor: props => (props.isDark ? '#07224D' : '#EFEFEF'),
  },
  '&::-webkit-scrollbar-thumb': {
    borderRadius: '10px',
    background: variables.$helperTextColor,
  },
};

const DisableNoneLayer = {
  position: 'relative',
  '&::after': {
    content: '""',
    display: 'block',
    border: '0',
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: props =>
      `${props.isDark ? '#0a29559e' : '#ffffffa8'}!important`,
    zIndex: '999',
    left: '0',
    top: '0',
    transform: 'scale(1)',
  },
  '& .MuiInputAdornment-root': {
    zIndex: '-1 !important',
  },
};

export {
  FlexInputsWrapper,
  CheckedIconBefore,
  MainContent,
  AddListIconAddExtend,
  StickerBadgeSilver,
  StickerBadgeGold,
  IconActiveState,
  LabelTag,
  PopUpActionsBtn,
  OverflowThumb,
  DisableNoneLayer,
};
