import cookie from 'js-cookie';
import io from 'socket.io-client';
import { useEffect } from 'react';

import { ENV } from 'utils/constants';
import { logout } from 'utils/handlers';

import { getUserAccountId } from 'store/reselect';
import {
  getUnreadCount,
  setNeedToUpdate,
  setNeedToUpdateOnlineAd,
} from 'store/actions';

const actions = {
  messageCreated: (store = {}) => {
    const { dispatch, getState = () => {} } = store;
    const accountId = getUserAccountId(getState());

    dispatch(getUnreadCount(accountId));
    dispatch(setNeedToUpdate(true));
  },
  sessionExpired: logout,
  messageUnreadCount: (store = {}) => {
    const { dispatch, getState = () => {} } = store;
    const accountId = getUserAccountId(getState());
    dispatch(getUnreadCount(accountId));
  },
  profileAdToOnline: store => {
    const { dispatch } = store;
    dispatch?.(setNeedToUpdateOnlineAd(true));
  },
  shoppingCartUpdated: store => {
    const { dispatch, getState } = store;
    const accountId = getUserAccountId(getState());
    import('store/toolkit/payments').then(({ getShoppingCartCount }) =>
      dispatch?.(getShoppingCartCount(accountId)),
    );
  },

  invoicePdf: (_, data) => {
    const { url } = data;
    const link = document.createElement('a');
    link.href = `${url}?token=${cookie.get('token')}`;
    link.download = url.split('/').pop();
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },
};

const useSocketIo = store => {
  const token = cookie.get('token');

  useEffect(() => {
    if (token) {
      const accountId = getUserAccountId(store?.getState?.());

      const ioInterior = io(ENV.wsUrl, {
        query: {
          token: token,
          account_id: accountId,
        },
        withCredentials: true,
        transports: ['websocket'],
      });

      ioInterior.on('connect', () => {
        console.log(
          '%c[open] Connection established',
          'background: #079992; color: #ffffff; padding: 3px 5px; border-radius: 3px',
        );
      });

      ioInterior.on('interior:socket', ({ data, type } = {}) => {
        actions[type]?.(store, data);
      });

      ioInterior.on('disconnect', () => {
        console.log(
          '%c[close] Connection died',
          'background: #c0392b; color: #ffffff; padding: 3px 5px; border-radius: 3px',
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);
};

export default useSocketIo;
