import { useCallback, useState, useRef, useEffect } from 'react';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import useMount from 'hooks/use-mount';
import useMemoSelector from 'hooks/useMemoSelector';

import { SEARCH_MIN_LENGTH } from 'utils/constants';

import {
  getProvinces,
  getSelectedProvince,
  getSelectedCategory,
} from 'store/reselect';
import {
  setSearchSuggestionsOpen,
  setMobileSearchOpen,
  fetchLocations,
  fetchPopularCities,
} from 'store/actions';

import Nearby from './components/Nearby';
import Provinces from './components/Provinces';
import PopularCities from './components/PopularCities';
import CitiesListing from './components/CitiesListing';
import SelectedProvince from './components/SelectedProvince';
import SuggestionsListing from './components/SuggestionsListing';
import { useStyles } from './styles';
import Location from './components/Location';
import SearchByText from './components/SearchByText';

const Suggestions = ({
  isMobile,
  searchValue,
  suggestions,
  headerHeight,
  setSearchValue,
}) => {
  const classes = useStyles();
  const suggestionListRef = useRef();

  const t = useTranslations();
  const dispatch = useDispatch();
  const router = useRouter();

  const { provinces, selectedProvince, selectedCategory } = useMemoSelector(
    store => ({
      provinces: getProvinces(store),
      selectedProvince: getSelectedProvince(store),
      selectedCategory: getSelectedCategory(store, router.query.slug),
    }),
  );

  useEffect(() => {
    dispatch(
      fetchLocations({
        ...(selectedCategory && { categoryId: selectedCategory.id }),
        cb: () => {},
      }),
    );

    dispatch(
      fetchPopularCities({
        ...(selectedCategory && { categoryId: selectedCategory.id }),
        cb: () => {},
      }),
    );
  }, [dispatch, selectedCategory]);

  const [windowHeight, setWindowHeight] = useState(0);

  const handleSubmit = useCallback(
    ({ term, type }) => {
      dispatch(setSearchSuggestionsOpen(false));
      dispatch(setMobileSearchOpen(false));
      setSearchValue(term || t(type));

      if (term || type) {
        router.push({
          pathname:
            selectedCategory && !type
              ? `/${selectedCategory.value_nl}`
              : '/search',
          query: {
            ...(term && { term }),
            ...(type && { type, distance: '15' }),
            ...(selectedCategory &&
              type && { category_id: selectedCategory.id }),
          },
        });
      }
    },
    [dispatch, router, selectedCategory, setSearchValue],
  );

  useMount(() => {
    if (typeof window !== 'undefined') {
      setWindowHeight(window.innerHeight);
    }
  });

  const allCities = Object.values(provinces).flatMap(({ cities }) => cities);

  return (
    <div className={classes['suggestions-wrapper']}>
      <hr />
      <ul
        ref={suggestionListRef}
        className={classes['suggestions-list']}
        style={{
          ...(isMobile && { height: 'calc(100dvh - 68px)' }),
          ...(!isMobile && { maxHeight: `${windowHeight - headerHeight}px` }),
        }}
      >
        {!selectedProvince ? (
          <>
            {!searchValue && <Nearby t={t} handleSubmit={handleSubmit} />}

            {searchValue?.length >= SEARCH_MIN_LENGTH && (
              <SearchByText
                searchValue={searchValue}
                handleSubmit={handleSubmit}
              />
            )}

            {!suggestions?.length && (
              <>
                <PopularCities />
                <Provinces
                  provinces={Object.keys(provinces)}
                  suggestionListRef={suggestionListRef}
                />
              </>
            )}

            {searchValue && !!suggestions.length && (
              <SuggestionsListing suggestions={suggestions} />
            )}

            {/* SEO case */}
            {typeof window === 'undefined' && (
              <div style={{ display: 'none' }}>
                {allCities.map(
                  ({ name, count, is_popular, province_code }, i) => (
                    <Location
                      name={name}
                      adCount={count}
                      key={`${name}-${i}`}
                      locationKey={is_popular ? 'popular_city' : 'city'}
                      province_code={province_code}
                    >
                      <span>{name}</span>
                    </Location>
                  ),
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <SelectedProvince
              provinces={provinces}
              selectedProvince={selectedProvince}
            />
            <CitiesListing cities={provinces[selectedProvince]?.cities} />
          </>
        )}
      </ul>
    </div>
  );
};
export default Suggestions;
