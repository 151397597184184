import { memo } from 'react';
import clsx from 'clsx';

const defaultImageSrc = '/images/icons/upload.png';

const Image = ({
  src,
  styles,
  alt = '',
  defaultSrc,
  onClick = () => void 0,
}) => (
  <img
    alt={alt}
    loading="lazy"
    style={styles}
    onClick={onClick}
    src={src?.length ? src : defaultSrc || defaultImageSrc}
    className={clsx({ 'default-avatar': defaultSrc })}
    onError={({ target }) => {
      target.onerror = null;
      target.src = defaultImageSrc;
    }}
  />
);

export default memo(Image);
