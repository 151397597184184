import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  useLayoutEffect,
} from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import TextField from '@material-ui/core/TextField';
import variablesJSS from 'static/styles/jss/abstracts/variables';
import { useTranslations } from 'next-intl';

import { COUNTRY_PHONES } from 'utils/constants';
import { UiGenerateMargin, UIGetMarginLeftRight } from 'utils/handlers';

import useStyles from './styles';

const canUseDOM = typeof window !== 'undefined';
React.useLayoutEffect = canUseDOM ? useLayoutEffect : useEffect;

const calculateIconPosition = (inputRef, defaultTop) => {
  // Calculate Icon's position
  if (inputRef) {
    const top =
      defaultTop ||
      inputRef.getBoundingClientRect().height / 2 -
        variablesJSS.$input.$iconSize / 2;
    return {
      translate: `translateY(-8px`,
      marginTop: `${top}px`,
    };
  }
  return {};
};

const Input = props => {
  const {
    hiddenBottomWrapper,
    inputMask,
    helperTextStyles,
    readonlyNoIcon,
    className,
    theme,
    readonly,
    halfSelected,
    likeMaterialInput,
    transparentInput,
    helperText,
    direction,
    textArea,
    required,
    passwordEye,
    fullWidth,
    maxCounter,
    minLength,
    size,
    id,
    refBind,
    name,
    label,
    type,
    width,
    errors,
    disabled,
    events,
    icon,
    customStylesDiv,
    customStylesInput,
    margin,
    attr,
    value,
    placeholder,
    customLimit,
  } = props;
  const t = useTranslations();
  const [inputMaskData, setInputMaskData] = useState({});
  const [allowTransition, setAllowTransition] = useState(false);
  const [inputRef, setInputRef] = useState(null);
  const [limitCount, setLimitCount] = useState(0);
  const [marginBottom, setMarginBottom] = useState(null);
  const [iconModel, setIconModel] = useState({});
  const [passwordTypeToggle, setPasswordTypeToggle] = useState(false);

  const styles = useStyles({
    disabled,
    transitionDuration: allowTransition ? '400ms' : '0s',
    backgroundColor: [customStylesInput.background, '!important'],
    border: customStylesInput.border,
    labelColor: (label && label.color) || variablesJSS.$input.$baseColor,
    iconTop: calculateIconPosition(
      inputRef,
      textArea && variablesJSS.$textArea.$iconTop,
    ),
    textAlign: customStylesInput.textAlign,
    marginBottom: marginBottom || '0px',
  });

  const parentRef = useRef();
  const errorRef = useRef();
  const bottomWrapRef = useRef();
  const labelRef = useRef();
  const inputWrapperRef = useRef();

  const setAttributes = useCallback(() => {
    // Set all attributes
    if (attr && inputRef) {
      Object.keys(attr).map(name => {
        if (name === 'checked') {
          if (attr[name]) inputRef.setAttribute(name, attr[name]);
          else inputRef.removeAttribute(name);
        } else {
          inputRef.setAttribute(name, attr[name]);
        }
      });
    }
  }, [attr, inputRef]);

  const counterCalculator = useCallback(() => {
    // Count the limited characters
    setLimitCount(inputRef.value.length);
  }, [inputRef]);

  const togglePassword = useCallback(() => {
    // Toggle Password Eye Icon
    setPasswordTypeToggle(!passwordTypeToggle);
  }, [passwordTypeToggle]);

  useEffect(() => {
    // Find and Set ref of Input/Textarea element
    if (parentRef.current) {
      setInputRef(
        parentRef.current.querySelector(textArea ? 'textarea' : 'input'),
      );
    }
  }, [textArea]);

  useEffect(() => {
    // Set/Remove events
    if (parentRef.current && inputRef) {
      setAttributes();
      for (let event = 0; event < events.length; event++) {
        // Add Event Listeners
        inputRef.addEventListener(
          events[event],
          props[
            `on${events[event].replace(
              events[event].charAt(0),
              events[event].charAt(0).toUpperCase(),
            )}`
          ],
        );
      }
    }
    return () => {
      if (parentRef.current && inputRef) {
        // Remove Event Listeners
        for (let event = 0; event < events.length; event++) {
          inputRef.removeEventListener(
            events[event],
            props[
              `on${events[event].replace(
                events[event].charAt(0),
                events[event].charAt(0).toUpperCase(),
              )}`
            ],
          );
        }
      }
    };
  }, [events, inputRef, props, setAttributes]);

  useEffect(() => {
    // Value Override
    if (inputRef) {
      inputRef.value = value || '';
    }
  }, [inputRef, value]);

  useEffect(() => {
    if (parentRef.current) setAttributes();
  }, [attr, inputRef, setAttributes]);

  useEffect(() => {
    if (value) {
      setLimitCount(String(value).length);
    }
    if (maxCounter && inputRef) {
      inputRef.setAttribute('maxlength', maxCounter);
      inputRef.addEventListener('keyup', counterCalculator);
    }
    if (minLength && inputRef) {
      inputRef.setAttribute('minlength', minLength);
      inputRef.addEventListener('keyup', counterCalculator);
    }
    return () => {
      inputRef && inputRef.removeEventListener('keyup', counterCalculator);
    };
  }, [maxCounter, value, inputRef, counterCalculator, minLength]);

  useEffect(() => {
    // Set Icon
    if (readonly && !readonlyNoIcon && !icon && !attr?.fakeReadonly) {
      setIconModel({
        className: 'icon-Lock',
      });
    } else if (errors && !passwordEye) {
      setIconModel({
        className: 'error-icon icon-Alert',
      });
    } else if (type === 'password' && passwordEye) {
      setIconModel({
        className: `icon-Views toggle-password${
          passwordTypeToggle ? ' icon-eye-blocked' : ''
        }`,
        onClick: togglePassword,
      });
    } else if (icon) {
      setIconModel({
        ...(icon.template && { template: icon.template }),
        className: icon.className + (icon.onClick ? ' clickable' : ''),
        ...(icon.onClick && { onClick: icon.onClick }),
      });
    } else {
      setIconModel({});
    }
  }, [
    passwordEye,
    type,
    icon,
    errors,
    passwordTypeToggle,
    readonly,
    readonlyNoIcon,
    attr?.fakeReadonly,
    togglePassword,
  ]);

  useEffect(() => {
    if (marginBottom) {
      setAllowTransition(true);
    }
  }, [marginBottom]);

  useEffect(() => {
    // Calculate Margin Bottom
    const bottomElementHeight = errorRef.current
      ? errorRef.current.offsetHeight + 2
      : bottomWrapRef.current
      ? bottomWrapRef.current.offsetHeight + 2
      : 0;
    setMarginBottom(
      `${
        bottomElementHeight /* marginTopBottom */ -
        (transparentInput
          ? variablesJSS.$input.$transparentInput.$errorDefaultHeight - 4
          : 0)
      }px`,
    );
  }, [required, errors, transparentInput, helperText, maxCounter]);

  useEffect(() => {
    if (inputMask?.phone_country_code) {
      const currentPhone = COUNTRY_PHONES.find(
        e => e.phone_country_code === +inputMask.phone_country_code,
      );
      if (currentPhone) {
        setInputMaskData({
          maskPlaceholder: currentPhone.phoneCode,
          maskValue: inputMask.maskValue,
        });
      }
    }
  }, [inputMask]);

  // Get position of Main Wrapper
  const generateMarginDiv = useCallback(
    () => UiGenerateMargin(margin, direction),
    [direction, margin],
  );

  const inlineStylesParent = useMemo(
    () =>
      // Set inline styles on the Main Wrapper
      ({
        ...generateMarginDiv(),
        width: fullWidth
          ? `calc(100% - ${UIGetMarginLeftRight(margin)}px)`
          : width,
        ...(label && {
          display: 'flex',
          flexDirection: 'column',
        }),
        ...(!label && {
          display: 'block',
        }),
        ...customStylesDiv,
      }),
    [generateMarginDiv, fullWidth, margin, width, label, customStylesDiv],
  );

  const iconsDOM = useMemo(() => {
    if (iconModel.template) return iconModel.template;
    if (iconModel.className)
      return (
        <div onClick={iconModel.onClick} className="input-icon">
          <i onClick={iconModel.onClick} className={iconModel.className}></i>
        </div>
      );
  }, [iconModel]);

  const generateClassNameParent = useMemo(
    () =>
      `${`${size}-parent-wrapper`} ${
        disabled ? 'input-wrap--disabled' : ''
      } ${className} ${likeMaterialInput ? 'material-input-wrapper' : ''} ${
        transparentInput ? styles['transparent-input-wrap'] : ''
      } ${styles[theme ? `theme-${theme}` : '']} ${styles['input-wrap']} ${
        iconModel.className ? styles['with-icon'] : ''
      } ${errors ? 'error-field' : ''} ${
        type === 'checkbox'
          ? styles['wrap-with-checkbox']
          : type === 'radio'
          ? styles['wrap-with-radio']
          : ''
      }`,
    [
      size,
      disabled,
      className,
      likeMaterialInput,
      transparentInput,
      styles,
      theme,
      iconModel.className,
      errors,
      type,
    ],
  );

  const labelDOM = useMemo(() => {
    if (label) {
      const textLabel = label.title + (required ? ' *' : '');
      return (
        <label
          ref={labelRef}
          htmlFor={type === 'checkbox' || type === 'radio' ? label.forId : null}
          className={`label-${size}`}
          dangerouslySetInnerHTML={{ __html: textLabel }}
        ></label>
      );
    }
  }, [type, required, label, size]);

  const bottomWrap = useMemo(() => {
    if (hiddenBottomWrapper) return null;
    if (maxCounter || helperText || required) {
      const text = helperText || (required ? t('required_field_text') : '');
      return (
        <>
          <div className="bottom-wrap" ref={bottomWrapRef}>
            {text && !errors && (
              <p className="helper-text" style={helperTextStyles}>
                {text}
              </p>
            )}
            {maxCounter && (
              <span className="counter">
                {customLimit || limitCount}/{maxCounter}
              </span>
            )}
          </div>
          {errors && (
            <span ref={errorRef} className={'error-line'}>
              {errors}
            </span>
          )}
        </>
      );
    }
  }, [
    helperText,
    errors,
    maxCounter,
    errorRef,
    limitCount,
    required,
    hiddenBottomWrapper,
  ]);

  return (
    <div
      style={inlineStylesParent}
      className={generateClassNameParent}
      ref={parentRef}
    >
      {labelDOM}
      <div ref={inputWrapperRef} className="input-element-wrapper">
        {textArea ? (
          <textarea
            name={name}
            required={required}
            cols={textArea.cols || 30}
            rows={textArea.rows || 5}
            className={`textarea-${size}`}
            style={customStylesInput}
            ref={refBind}
            placeholder={placeholder || 'Input text...'}
            readOnly={readonly}
            tabIndex={readonly ? -1 : 0}
          ></textarea>
        ) : likeMaterialInput ? (
          <TextField
            required={required}
            readOnly={readonly}
            disabled={disabled}
            placeholder={placeholder || ''}
            className={`input-${size}`}
            ref={refBind}
            type={type}
            name={name}
            style={customStylesInput}
          />
        ) : inputMask ? (
          <InputMask
            required={required}
            className={`input-${size}`}
            value={inputMaskData.maskValue || ''}
            mask={inputMaskData.maskPlaceholder}
            onChange={e => void e}
            disabled={false}
            placeholder={inputMaskData.maskPlaceholder?.replaceAll('\\', '')}
            readOnly={readonly}
            style={customStylesInput}
            maskChar=""
          />
        ) : (
          <input
            autoComplete="off"
            required={required}
            tabIndex={readonly ? -1 : 0}
            readOnly={readonly}
            style={customStylesInput}
            name={name}
            className={`input-${size}`}
            ref={refBind}
            type={(passwordTypeToggle && 'text') || type}
            id={
              label && (type === 'checkbox' || type === 'radio')
                ? label.forId
                : id
            }
            disabled={disabled}
            placeholder={placeholder || ''}
          />
        )}
        {type === 'radio' || type === 'checkbox' ? (
          <span
            className={`checkbox-wrap ${
              halfSelected ? 'selected-half-part' : ''
            }`}
          ></span>
        ) : (
          ''
        )}
        {iconsDOM}
      </div>
      {bottomWrap}
    </div>
  );
};

Input.defaultProps = {
  size: 'md',
  type: 'text',
  fullWidth: false,
  events: [],
  margin: 0,
  required: false,
  readonly: false,
  hiddenBottomWrapper: false,
  transparentInput: false,
  theme: 'light',
  customStylesInput: {},
  customStylesDiv: {},
  readonlyNoIcon: false,
  className: '',
};
Input.propTypes = {
  readonlyNoIcon: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.string,
  readonly: PropTypes.bool,
  halfSelected: PropTypes.bool,
  likeMaterialInput: PropTypes.bool,
  transparentInput: PropTypes.bool,
  helperText: PropTypes.string,
  direction: PropTypes.string,
  textArea: PropTypes.object,
  required: PropTypes.bool,
  hiddenBottomWrapper: PropTypes.bool,
  passwordEye: PropTypes.bool,
  inputMask: PropTypes.object,
  fullWidth: PropTypes.bool,
  maxCounter: PropTypes.number,
  size: PropTypes.string,
  id: PropTypes.string,
  refBind: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.object,
  type: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  disabled: PropTypes.bool,
  events: PropTypes.array,
  icon: PropTypes.object,
  customStylesDiv: PropTypes.object,
  customStylesInput: PropTypes.object,
  margin: PropTypes.oneOfType([PropTypes.array, PropTypes.number]),
  attr: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ]),
  placeholder: PropTypes.string,
};
export default React.memo(Input);
