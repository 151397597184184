const locationCombinationWithCategories = {
  all: [3],
  province: [3],
  city: [9, 2, 1],
  popular_city: [2, 4, 5, 7, 8, 9, 10, 11, 12, 13],
};

const labelsWithoutProvinceLinking = [5, 19, 8, 10, 55, 92];

const routeConstructor = ({
  name,
  adCount,
  category,
  subCategory,
  locationKey,
  province_code,
}) => {
  const nameWithCorrectFormat = name?.toLowerCase().replace(/ /g, '-');

  const cityName = province_code
    ? `${nameWithCorrectFormat}-${province_code}`
    : `${nameWithCorrectFormat}`;

  if (category) {
    const withInternalLinking =
      locationCombinationWithCategories[locationKey].includes(category.id) ||
      locationCombinationWithCategories.all.includes(category.id) ||
      labelsWithoutProvinceLinking.includes(subCategory?.id);

    const categoryLink = subCategory
      ? `${category.value_nl}/${subCategory.value_nl}`
      : category.value_nl;

    if (withInternalLinking) {
      if (locationKey === 'city') {
        if (!adCount) {
          return `/${categoryLink}?settlement=${nameWithCorrectFormat}`;
        } else {
          return `/${categoryLink}/${cityName}`;
        }
      }

      if (locationKey === 'province') {
        if (
          !adCount ||
          labelsWithoutProvinceLinking.includes(subCategory?.id)
        ) {
          return `/${categoryLink}?province=${nameWithCorrectFormat}`;
        }
        return `/${categoryLink}/provincie-${nameWithCorrectFormat}`;
      }
      if (locationKey === 'popular_city') {
        if (!adCount) {
          return `/${categoryLink}?settlement=${cityName}`;
        } else {
          return `/${categoryLink}/${cityName}`;
        }
      }
    }

    if (!withInternalLinking)
      return `/${categoryLink}?${
        locationKey === 'province' ? 'province' : 'settlement'
      }=${nameWithCorrectFormat}`;
  } else {
    if (locationKey === 'city') {
      if (!adCount) {
        return `/search?settlement=${cityName}`;
      } else {
        return `/${cityName}`;
      }
    }

    if (locationKey === 'popular_city') {
      if (!adCount) {
        return `/search?settlement=${cityName}`;
      } else {
        return `/${cityName}`;
      }
    }

    if (locationKey === 'province') {
      if (!adCount) {
        return `/search?province=${nameWithCorrectFormat}`;
      }
      return `/provincie-${nameWithCorrectFormat}`;
    }
  }
};

export default routeConstructor;
