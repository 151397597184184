import { memo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import useMemoSelector from 'hooks/useMemoSelector';

import { adCategories } from 'store/reselect';
import {
  setMobileSearchOpen,
  setSelectedProvince,
  setSelectedSettlement,
  setSearchSuggestionsOpen,
} from 'store/actions';

import routeConstructor from '../helpers/routeConstructor';
import { useSuggestionItemStyle } from '../styles';

const Location = ({ name, adCount, children, locationKey, province_code }) => {
  const router = useRouter();
  const dispatch = useDispatch();
  const categories = useMemoSelector(adCategories);
  const { suggestionItem } = useSuggestionItemStyle();

  const category = categories?.find(
    ({ value_nl }) => value_nl === router.query.slug?.[0],
  );

  const subCategory = category?.labels.find(
    ({ value_nl }) => value_nl === router.query.slug?.[1],
  );

  const href = routeConstructor({
    name,
    adCount,
    category,
    subCategory,
    locationKey,
    province_code,
  });

  return (
    <Link href={href}>
      <a
        onClick={() => {
          dispatch(setSelectedProvince(''));
          if (locationKey !== 'province') {
            dispatch(setSelectedSettlement(name));
          }
          dispatch(setMobileSearchOpen(false));
          dispatch(setSearchSuggestionsOpen(false));
        }}
        className={clsx(suggestionItem, 'location-item')}
      >
        {children}
      </a>
    </Link>
  );
};

export default memo(Location);
