import { memo } from 'react';
import { BsFillGeoAltFill, BsBuildings } from 'react-icons/bs';
import clsx from 'clsx';
import { useTranslations } from 'next-intl';

import { useLocationStyles } from '../styles';

import Location from './Location';

let locationKey;

const SuggestionsListing = ({ suggestions }) => {
  const t = useTranslations();
  const {
    countryStyle,
    locationWrapper,
    settlementStyle,
    postalCodeStyle,
    locationIconStyle,
  } = useLocationStyles();

  return suggestions?.map(
    ({
      type,
      count,
      country,
      is_popular,
      settlement,
      postal_code,
      province_code,
      province: provinceName,
    }) => {
      const isProvince = type === 'province';
      const countryName = country === 'be' ? t('belgium') : t('netherlands');

      if (is_popular) {
        locationKey = 'popular_city';
      } else if (isProvince) {
        locationKey = 'province';
      } else {
        locationKey = 'city';
      }

      return (
        <Location
          adCount={count}
          key={Math.random()}
          locationKey={locationKey}
          province_code={province_code}
          name={isProvince ? provinceName : settlement}
        >
          {type === 'postal_code' ? (
            <BsBuildings className={locationIconStyle} />
          ) : (
            <BsFillGeoAltFill className={locationIconStyle} />
          )}
          <div>
            <div>{postal_code}</div>
            <div
              className={clsx({
                [postalCodeStyle]: postal_code,
              })}
            >
              {type === 'postal_code' ? (
                `${settlement}, ${countryName}`
              ) : (
                <div className={locationWrapper}>
                  <span className={settlementStyle}>
                    {isProvince ? provinceName : settlement}
                  </span>
                  {country && (
                    <span className={countryStyle}>
                      {`${
                        isProvince ? t('province') : provinceName
                      }, ${countryName}`}
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        </Location>
      );
    },
  );
};

export default memo(SuggestionsListing);
