// eslint-disable-next-line import/prefer-default-export
export const sort = key => (a, b) => {
  if (a[key] < b[key]) {
    return -1;
  }
  if (a[key] > b[key]) {
    return 1;
  }
  return 0;
};

export const isEmpty = value => {
  if (value == null) {
    return true;
  }

  if (Array.isArray(value) || typeof value === 'string') {
    return value.length === 0;
  }

  if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }

  return false;
};

export const isObject = value =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

export function cleanupObject(obj) {
  for (const key in obj) {
    if (isObject(obj[key]) && !isEmpty(obj[key])) {
      cleanupObject(obj[key]);
      if (Object.keys(obj[key]).length === 0) {
        delete obj[key];
      }
    } else if (isEmpty(obj[key])) {
      delete obj[key];
    }
  }
}

export const oneSpaceString = str =>
  str
    .split('')
    .filter(
      (val1, index, arr) =>
        index == 0 || val1 !== ' ' || arr[index - 1] !== ' ',
    )
    .join('');
